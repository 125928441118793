import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { GoaaaEnvironment } from '@goaaa-mwg-tt/ionic-common';
import * as Sentry from '@sentry/browser';
/**
 * Enables logging to StackDriver.
 */
var SentryLoggingService = /** @class */ (function () {
    function SentryLoggingService(http, env) {
        this.http = http;
        this.env = env;
        Sentry.init({
            dsn: 'https://d8597dbac0644070bf9b64ed9e95dcbf@sentry.io/1729830',
            environment: this.env.projectId,
            integrations: function (integrations) {
                return integrations.filter(function (i) { return i.name !== 'TryCatch'; });
            }
        });
        Sentry.setTag('serviceName', 'aaa-otw-member-app');
    }
    Object.defineProperty(SentryLoggingService.prototype, "appName", {
        set: function (name) {
            this._appName = name;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SentryLoggingService.prototype, "appVersion", {
        set: function (version) {
            this._appVersion = version;
            Sentry.setTag('serviceVersion', this._appVersion);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SentryLoggingService.prototype, "user", {
        set: function (user) {
            this._user = user;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SentryLoggingService.prototype, "serviceCallId", {
        set: function (serviceCallId) {
            this._serviceCallId = serviceCallId;
        },
        enumerable: true,
        configurable: true
    });
    SentryLoggingService.prototype.error = function (error, extra) {
        var _this = this;
        if (extra === void 0) { extra = null; }
        if (!error) {
            return;
        }
        if (typeof error === 'string') {
            return this.message(error, extra);
        }
        Sentry.withScope(function (scope) {
            // scope.setLevel('error');
            scope.setTag('serviceCallId', _this._serviceCallId);
            scope.setTag('memberAccessToken', _this._user);
            // scope.setTag('version', this._appVersion);
            if (extra) {
                scope.setExtra(extra.key, extra.content);
            }
            if (error instanceof HttpErrorResponse || error.hasOwnProperty('status')) {
                scope.setLevel(Sentry.Severity.Error);
                scope.setExtra('url', error.url);
                if (error.hasOwnProperty('error')) {
                    scope.setExtra('message', error.error.message);
                }
                Sentry.captureMessage("HTTP Error: " + error.status);
            }
            else {
                Sentry.captureException(error.originalError || error);
            }
        });
    };
    SentryLoggingService.prototype.message = function (message, extra, severityLevel) {
        var _this = this;
        if (extra === void 0) { extra = null; }
        if (severityLevel === void 0) { severityLevel = 'info'; }
        Sentry.withScope(function (scope) {
            scope.setLevel(Sentry.Severity.fromString(severityLevel));
            scope.setTag('serviceCallId', _this._serviceCallId);
            scope.setTag('memberAccessToken', _this._user);
            // scope.setTag('version', this._appVersion);
            if (extra) {
                scope.setExtra(extra.key, extra.content);
            }
            Sentry.captureMessage(message);
        });
    };
    return SentryLoggingService;
}());
export { SentryLoggingService };
