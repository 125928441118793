var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { AppHttpInterceptorService, GoaaaEnvironment } from '@goaaa-mwg-tt/ionic-common';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import * as i0 from "@angular/core";
import * as i1 from "@goaaa-mwg-tt/ionic-common/dist/environments/environment";
import * as i2 from "@angular/router";
import * as i3 from "@angular/common/http";
var MemberHttpInterceptorService = /** @class */ (function (_super) {
    __extends(MemberHttpInterceptorService, _super);
    function MemberHttpInterceptorService(env, router, http) {
        var _this = _super.call(this, env, http) || this;
        _this.router = router;
        return _this;
    }
    /**
     * This method is used to navigate to 'ErrorPage' along with error type
     * to handle errors in Error page accordingly.
     * @param err Error
     */
    MemberHttpInterceptorService.prototype.handleError = function (err) {
        // if (err instanceof HttpErrorResponse) {
        //     switch (err.status) {
        //         case 416:
        //             this.router.navigate(['/error/416'], { queryParamsHandling: 'preserve' });
        //             break;
        //         case 417:
        //             this.router.navigate(['/error/417'], { queryParamsHandling: 'preserve' });
        //             break;
        //         default:
        //             this.router.navigate(['/error/500'], { queryParamsHandling: 'preserve' });
        //             break;
        //     }
        // }
        throw err;
    };
    MemberHttpInterceptorService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function MemberHttpInterceptorService_Factory() { return new MemberHttpInterceptorService(i0.ɵɵinject(i1.GoaaaEnvironment), i0.ɵɵinject(i2.Router), i0.ɵɵinject(i3.HttpClient)); }, token: MemberHttpInterceptorService, providedIn: "root" });
    return MemberHttpInterceptorService;
}(AppHttpInterceptorService));
export { MemberHttpInterceptorService };
