var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { HttpClient } from '@angular/common/http';
import { ServiceCall, Driver, Truck, GoaaaEnvironment } from '@goaaa-mwg-tt/ionic-common';
import { Events } from '@ionic/angular';
import { AppDataService } from './app-data.service';
import * as firebase from 'firebase/app';
import { firestore } from 'firebase/app';
import 'firebase/database';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage';
import { SentryLoggingService } from './sentry-logging.service';
import { retryWithBackoff } from '../rxjs-operators/retry-with-backoff';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "./app-data.service";
import * as i3 from "@ionic/angular";
import * as i4 from "./sentry-logging.service";
import * as i5 from "@goaaa-mwg-tt/ionic-common/dist/environments/environment";
var FirebaseResource = /** @class */ (function () {
    function FirebaseResource() {
    }
    return FirebaseResource;
}());
/**
 * Sets up Firebase listeners that are needed by the app.
 */
var FirebaseDataService = /** @class */ (function () {
    function FirebaseDataService(http, appData, events, log, env) {
        var _this = this;
        this.http = http;
        this.appData = appData;
        this.events = events;
        this.log = log;
        this.env = env;
        this.previousResources = new Map();
        this.previousOtwEnabled = null;
        this.apps = new Map();
        this.firestore = null;
        this.firebaseConfig = null;
        this.callClosed = false;
        this.callClosedStatuses = ['CA', 'CL', 'KI'];
        this.memberAccessToken = null;
        this.lastLocationUpdate = null;
        this.listenerPostUnsubscribeFunctions = {
            driverDetails: function () {
                _this.appData.driverDetails = null;
            },
            serviceCallDetails: function () {
                _this.appData.serviceCallDetails = null;
            },
            truckDetails: function () {
                _this.appData.truckDetails = null;
            },
            truckActivity: function () {
                console.log('Truck activity listener unsubscribed');
                _this.appData.truckDataAvailable = false;
            },
            truckLocation: function () {
                _this.appData.truckLocationWatchdogRef = null;
                _this.appData.truckLocation = null;
            }
        };
        this.listenerSetupFunctions = {
            driverDetails: function (resource) {
                // Make sure the data is in Firestore
                // expect(resource.descriptor.dbType).toEqual('firestore');
                console.log('Setting up driverDetails listener');
                // Set up the listener
                resource.listenerUnsubscribe = _this.firestore.doc(resource.descriptor.path)
                    .onSnapshot(function (snapshot) {
                    if (snapshot.exists) {
                        // console.log(`Driver data changed: `, getUpdateDelta(snapshot.data(), resource.data));
                        resource.data = _this.convertTimeStamps(snapshot.data());
                        _this.appData.driverDetails = new Driver(resource.data);
                        console.log("Driver data changed: ", resource.data);
                    }
                    else {
                        console.log("Driver does not exist at path: " + resource.descriptor.path);
                    }
                }, function (error) {
                    _this.handleListenerError(error, resource.descriptor.path, { key: 'listener', content: 'driverDetails' });
                });
            },
            serviceCallDetails: function (resource) {
                // Make sure the data is in Firestore
                // expect(resource.descriptor.dbType).toEqual('firestore');
                console.log('Setting up serviceCallDetails listener');
                // Set up the listener
                resource.listenerUnsubscribe = _this.firestore.doc(resource.descriptor.path)
                    .onSnapshot(function (snapshot) {
                    if (snapshot.exists) {
                        // console.log(`Driver data changed: `, getUpdateDelta(snapshot.data(), resource.data));
                        resource.data = _this.convertTimeStamps(snapshot.data());
                        var sc = new ServiceCall(resource.data);
                        console.log("Service call data changed: ", sc);
                        // Check for changes in the status
                        if (sc.currentStatus !== null) {
                            var previousCallClosed = _this.callClosed;
                            _this.callClosed = _this.callClosedStatuses.includes(sc.currentStatus);
                            if (_this.callClosed && !previousCallClosed) {
                                // this.log.message('Service call closed');
                                _this.clearListeners();
                            }
                            if (_this.appData.serviceCallDetails === null) {
                                // Notify subscribers of changes
                                _this.events.publish('service-calls-status:updated', {
                                    previous: null,
                                    current: sc.currentStatus
                                });
                            }
                            else if (_this.appData.serviceCallDetails.currentStatus !== sc.currentStatus) {
                                // Notify subscribers of changes
                                _this.events.publish('service-calls-status:updated', {
                                    previous: _this.appData.serviceCallDetails.currentStatus,
                                    current: sc.currentStatus
                                });
                            }
                        }
                        // Update the information in the AppData service
                        _this.appData.serviceCallDetails = sc;
                        _this.appData.facilityId = sc.facilityId;
                        _this.appData.truckId = sc.truckId;
                        _this.appData.driverId = sc.driverId;
                    }
                    else {
                        console.log("Service call does not exist at path: " + resource.descriptor.path);
                    }
                }, function (error) {
                    _this.handleListenerError(error, resource.descriptor.path, { key: 'listener', content: 'serviceCallDetails' });
                });
            },
            truckDetails: function (resource) {
                // Make sure the data is in Firestore
                // expect(resource.descriptor.dbType).toEqual('firestore');
                console.log('Setting up truckDetails listener');
                // Set up the listener
                resource.listenerUnsubscribe = _this.firestore.doc(resource.descriptor.path)
                    .onSnapshot(function (snapshot) {
                    if (snapshot.exists) {
                        // console.log(`Driver data changed: `, getUpdateDelta(snapshot.data(), resource.data));
                        resource.data = _this.convertTimeStamps(snapshot.data());
                        _this.appData.truckDetails = new Truck(resource.data);
                        console.log("Truck data changed: ", resource.data);
                    }
                    else {
                        console.log("Truck does not exist at path: " + resource.descriptor.path);
                    }
                }, function (error) {
                    _this.handleListenerError(error, resource.descriptor.path, { key: 'listener', content: 'truckDetails' });
                });
            },
            truckActivity: function (resource) { return __awaiter(_this, void 0, void 0, function () {
                var rtdb, ref, onValueChange;
                var _this = this;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // Make sure the data is in the realtime database
                            // expect(resource.descriptor.dbType).toEqual('realtime');
                            console.log('Setting up truckActivity listener');
                            return [4 /*yield*/, this.getRTDB(resource.descriptor.databaseURL)];
                        case 1:
                            rtdb = _a.sent();
                            ref = rtdb ? rtdb.ref(resource.descriptor.path) : null;
                            // If we don't have a database reference, there's nothing to do
                            if (!ref) {
                                return [2 /*return*/];
                            }
                            console.log("Creating truckActivity listener at path: " + ref.toString());
                            onValueChange = ref.on('value', function (snapshot) {
                                if (snapshot.exists()) {
                                    console.log('Truck is active');
                                    _this.appData.truckDataAvailable = true;
                                }
                                else {
                                    console.log('Truck is inactive');
                                    _this.appData.truckDataAvailable = false;
                                }
                            }, function (error) {
                                _this.handleListenerError(error, resource.descriptor.path, { key: 'listener', content: 'truckActivity' });
                            });
                            // Set up the unsubscribe function
                            resource.listenerUnsubscribe = function () {
                                ref.off('value', onValueChange);
                            };
                            return [2 /*return*/];
                    }
                });
            }); },
            truckLocation: function (resource) { return __awaiter(_this, void 0, void 0, function () {
                var rtdb, ref, onValueChange;
                var _this = this;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            // Make sure the data is in the realtime database
                            // expect(resource.descriptor.dbType).toEqual('realtime');
                            console.log('Setting up truckLocation listener');
                            return [4 /*yield*/, this.getRTDB(resource.descriptor.databaseURL)];
                        case 1:
                            rtdb = _a.sent();
                            ref = rtdb ? rtdb.ref(resource.descriptor.path) : null;
                            // If we don't have a database reference, there's nothing to do
                            if (!ref) {
                                return [2 /*return*/];
                            }
                            console.log("Creating truckLocation listener at path: " + ref.toString());
                            // Clear any previous watchdog timer (clearInterval called in AppData)
                            this.appData.truckLocationWatchdogRef = null;
                            // Set up the truck location watchdog timer
                            this.lastLocationUpdate = null;
                            this.appData.truckLocationWatchdogRef = window.setInterval(function () {
                                // console.log(`truckLocation watchdog: lastLocationUpdate = ${this.lastLocationUpdate}`);
                                var watchdogTimeoutMs = 15000;
                                if (_this.lastLocationUpdate && (Date.now() - _this.lastLocationUpdate) > watchdogTimeoutMs) {
                                    console.log("Location watchdog timeout (" + watchdogTimeoutMs / 1000 + " seconds)");
                                    // Show can't connect to truck message
                                    _this.appData.truckDataAvailable = false;
                                    // Log an error to Sentry
                                    // this.log.message('Truck location timeout',
                                    //     { key: 'details', content: { truckLocation: this.appData.truckLocation } }, 'error');
                                    // Reset the watchdog so we don't generate more error messages unless it times out again
                                    _this.lastLocationUpdate = Date.now();
                                }
                            }, 15000);
                            onValueChange = ref.on('value', function (snapshot) {
                                if (snapshot.exists()) {
                                    // Indicate that we received a location update
                                    _this.lastLocationUpdate = Date.now();
                                    // console.log(`truckLocation listener: lastLocationUpdate = ${this.lastLocationUpdate}`);
                                    // console.log(`Truck location: ${JSON.stringify(snapshot.val())}`);
                                    // Notify subscribers of changes
                                    _this.appData.truckLocation = snapshot.val();
                                    // If we we're previously indicating that we can't connect to the truck, indicate that we are now getting data
                                    if (!_this.appData.truckDataAvailable) {
                                        _this.appData.truckDataAvailable = true;
                                    }
                                }
                                else {
                                    console.log("Truck location does not exist at path: " + resource.descriptor.path);
                                }
                            }, function (error) {
                                _this.handleListenerError(error, resource.descriptor.path, { key: 'listener', content: 'truckLocation' });
                            });
                            // Set up the unsubscribe function
                            resource.listenerUnsubscribe = function () {
                                ref.off('value', onValueChange);
                            };
                            return [2 /*return*/];
                    }
                });
            }); }
        };
        console.log('FirebaseAdapterProvider constructor');
        // Set up a subscription to the member access token so we can set up
        // Firebase as soon as it is available
        appData.memberAccessTokenObservable.subscribe(function (token) {
            // As long as there is a non-null key, store it
            if (token) {
                console.log("Using member access token of '" + token + "' for getting service call details");
                _this.memberAccessToken = token;
                // Set up Firebase now that we have the member access token
                _this.setupFirebase(token);
            }
        });
    }
    FirebaseDataService.prototype.handleListenerError = function (error, path, extra) {
        // If we know the call is already closed, don't do anything
        if (!this.callClosed) {
            console.error("Listener error on " + path + ": " + error.message);
            // this.log.error(error, extra);
            // Restart the connection to Firebase and the listeners.
            // If the call is closed, we will then automatically display the correct error message to the member
            if (!(this.appData[extra.content] === null || Object.keys(this.appData[extra.content]).length === 0)) {
                // Only attempt a full reconnection to firebase if listener errors occur after there was an initial
                // first connection where we got data for the specific resource (not null or empty object)
                this.clearListeners();
                this.setupFirebase(this.memberAccessToken);
            }
        }
    };
    FirebaseDataService.prototype.setupFirebase = function (token) {
        var _this = this;
        this.callClosed = false;
        // Make a call to the backend API to get the associated service call details
        //  from the member access token
        var url = this.env.endpoints.realtime + "/service-calls?appId=" + this.env.appName + "&memberAccessToken=" + token;
        this.http.get(url).pipe(retryWithBackoff(1000, 2, 1000, function (error) {
            if (error.hasOwnProperty('status') && [404, 409].includes(error.status)) {
                return false; // Don't retry if status is 409 (call is closed)
            }
            return true;
        }))
            .subscribe(function (config) {
            console.log(config);
            try {
                var pathParts = config.resourcesPath.split('/');
                _this.log.serviceCallId = pathParts[pathParts.length - 1];
                // Initialize firebase
                var app = _this.apps.get('[DEFAULT]');
                if (app == null) {
                    app = firebase.initializeApp(config.dbConfig);
                    _this.apps.set('[DEFAULT]', app);
                }
                _this.firebaseConfig = config;
                // Authenticate using the provided token
                firebase.auth().signInWithCustomToken(config.token)
                    .then(function (user) {
                    // this.log.message('Authentication successful', { key: 'user', content: user.user.uid });
                    console.log(user.user.uid);
                    // Provide storage access to the app data service
                    _this.appData.setFirebaseStorage(firebase.storage());
                    // Create a listener on the resources document
                    _this.firestore = firebase.firestore();
                    _this.firestore.doc(config.resourcesPath).onSnapshot(function (snapshot) { return __awaiter(_this, void 0, void 0, function () {
                        var resources, resourceName;
                        return __generator(this, function (_a) {
                            if (snapshot.exists) {
                                resources = snapshot.data();
                                console.log(resources);
                                if (resources.hasOwnProperty('otwEnabled')) {
                                    // If OTW is not enabled, clear out the truck and driver resources
                                    if (!resources.otwEnabled) {
                                        resources.truckDetails = null;
                                        resources.truckLocation = null;
                                        resources.truckActivity = null;
                                        resources.driverDetails = null;
                                        // this.appData.truckDataAvailable = false;
                                    }
                                    this.appData.otwEnabled = resources.otwEnabled;
                                }
                                for (resourceName in resources) {
                                    if (resources.hasOwnProperty(resourceName) &&
                                        Object.keys(this.listenerSetupFunctions).includes(resourceName)) {
                                        this.checkResourceUpdates(resourceName, resources[resourceName]);
                                    }
                                }
                                console.log('setupFirebase() succeeded');
                            }
                            else {
                                console.log("Resources document does not exist at path: " + config.resourcesPath);
                                // throw { status: 404 };
                            }
                            return [2 /*return*/];
                        });
                    }); }, function (error) {
                        _this.handleListenerError(error, config.resourcesPath, { key: 'listener', content: 'serviceCallResources' });
                    });
                });
            }
            catch (error) {
                console.error('Could not set up firebase listeners: ', error);
            }
        });
    };
    FirebaseDataService.prototype.convertTimeStamps = function (object) {
        for (var field in object) {
            if (object[field] instanceof Object) {
                if (object[field] instanceof firestore.Timestamp) {
                    var stamp = object[field];
                    object[field] = stamp.toDate().toISOString();
                }
                else {
                    // Call recursively to handle nested objects
                    this.convertTimeStamps(object[field]);
                }
            }
        }
        return object;
    };
    FirebaseDataService.prototype.checkResourceUpdates = function (resourceName, resourceDescriptor) {
        var resource = this.previousResources.get(resourceName);
        var previousResource = resource ? JSON.parse(JSON.stringify(resource)) : {};
        var resourceChanged = false;
        if (resource) {
            // If the resource descriptors don't match, the resource has changed and the listeners need to be updated
            if (!resource.descriptor || JSON.stringify(resource.descriptor) !== JSON.stringify(resourceDescriptor)) {
                resourceChanged = true;
                // Store the new descriptor
                resource.descriptor = resourceDescriptor;
                // If a previous listener exists, unsubscribe first
                if (resource.listenerUnsubscribe) {
                    resource.listenerUnsubscribe();
                    resource.listenerUnsubscribe = null;
                    if (this.listenerPostUnsubscribeFunctions.hasOwnProperty(resourceName)) {
                        this.listenerPostUnsubscribeFunctions[resourceName]();
                    }
                }
                // Set up the new listener
                if (resource.descriptor && resource.descriptor.path) {
                    this.listenerSetupFunctions[resourceName](resource);
                }
            }
        }
        else {
            // This is the first time setting up the listener, so create the resource object as well
            if (resourceDescriptor) {
                resourceChanged = true;
                this.previousResources.set(resourceName, {
                    descriptor: resourceDescriptor,
                    listenerUnsubscribe: null
                });
                this.listenerSetupFunctions[resourceName](this.previousResources.get(resourceName));
            }
        }
        if (resourceChanged) {
            // this.log.message('Service call resource changed', {
            //     key: 'details', content:
            //         { resourceName: resourceName, previous: previousResource.descriptor, current: resourceDescriptor }
            // });
        }
    };
    FirebaseDataService.prototype.getRTDB = function (url) {
        var rtdbApp = this.apps.get(url);
        if (!rtdbApp) {
            rtdbApp = firebase.initializeApp(Object.assign({}, this.firebaseConfig.dbConfig, { databaseURL: url }), url);
            this.apps.set(url, rtdbApp);
        }
        var auth = firebase.auth(rtdbApp);
        if (auth.currentUser !== null) {
            return Promise.resolve(firebase.database(rtdbApp));
        }
        return firebase.auth(rtdbApp).signInWithCustomToken(this.firebaseConfig.token)
            .then(function () {
            return Promise.resolve(firebase.database(rtdbApp));
        })
            .catch(function (err) {
            console.error(err);
        });
    };
    FirebaseDataService.prototype.clearListeners = function () {
        this.previousResources.forEach(function (value, key, map) {
            // Call stored unsubscribe function
            if (value && value.listenerUnsubscribe) {
                value.listenerUnsubscribe();
                value.listenerUnsubscribe = null;
            }
        });
    };
    FirebaseDataService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function FirebaseDataService_Factory() { return new FirebaseDataService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.AppDataService), i0.ɵɵinject(i3.Events), i0.ɵɵinject(i4.SentryLoggingService), i0.ɵɵinject(i5.GoaaaEnvironment)); }, token: FirebaseDataService, providedIn: "root" });
    return FirebaseDataService;
}());
export { FirebaseDataService };
