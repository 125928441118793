import { Routes } from '@angular/router';
import { AppConst } from './app-const';
var ɵ0 = { title: 'TechnicianAssigned' }, ɵ1 = { title: 'Error' }, ɵ2 = { title: 'Error' }, ɵ3 = { title: 'LookingForTechnician' }, ɵ4 = { title: 'ThankYou' }, ɵ5 = { title: 'Loading' };
/*tslint:disable*/
var routes = [
    { path: AppConst.PAGE_ROUTES.driverAssigned, loadChildren: './driver-assigned/driver-assigned.module#DriverAssignedPageModule', data: ɵ0 },
    { path: 'error', loadChildren: './error/error.module#ErrorPageModule', data: ɵ1 },
    { path: 'error/:error', loadChildren: './error/error.module#ErrorPageModule', data: ɵ2 },
    { path: AppConst.PAGE_ROUTES.lookingForDriver, loadChildren: './looking-for-driver/looking-for-driver.module#LookingForDriverPageModule', data: ɵ3 },
    { path: AppConst.PAGE_ROUTES.thankYou, loadChildren: './thank-you/thank-you.module#ThankYouPageModule', data: ɵ4 },
    { path: 'loading', loadChildren: './loading/loading.module#LoadingPageModule', data: ɵ5 },
];
var AppRoutingModule = /** @class */ (function () {
    function AppRoutingModule() {
    }
    return AppRoutingModule;
}());
export { AppRoutingModule };
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5 };
