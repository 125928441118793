var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { BehaviorSubject } from 'rxjs';
import { CommonAppSettingsProvider } from '@goaaa-mwg-tt/ionic-common';
import * as i0 from "@angular/core";
var ServiceCallStatus = /** @class */ (function () {
    function ServiceCallStatus(last, current) {
        this.current = current;
        this.last = last;
    }
    return ServiceCallStatus;
}());
export { ServiceCallStatus };
var AppDataService = /** @class */ (function (_super) {
    __extends(AppDataService, _super);
    // private _paramName: BehaviorSubject<string> = new BehaviorSubject(null);
    function AppDataService() {
        var _this = _super.call(this) || this;
        /**
         * The internal member access token representation.
         *
         * It is implemented as a BehaviorSubject for the following reasons:
         * <ul><li>If only a value is needed (not an observable), BehaviorSubjects
         *  track their last stored value.</li>
         * <li>An observable can still be returned so that other components can subscribe.</li>
         * <li>BehaviorSubjects are initialized with a default value. When a subscriptions is
         *  created, the default or last written value is returned immediately so if there are
         *  any delays in subscription creation, an initial value is always available. See the
         *  following reference:
         *  <a href="https://stackoverflow.com/questions/39494058/behaviorsubject-vs-observable">
         *  https://stackoverflow.com/questions/39494058/behaviorsubject-vs-observable</a>
         * </li></ul>
         */
        _this._memberAccessToken = new BehaviorSubject(null);
        _this._serviceCallStatus = new BehaviorSubject(null);
        _this._truckDataAvailable = new BehaviorSubject(null);
        _this._truckLocation = new BehaviorSubject(null);
        _this._truckLocationWatchdogRef = new BehaviorSubject(null);
        _this._otwEnabled = new BehaviorSubject(null);
        return _this;
    }
    Object.defineProperty(AppDataService.prototype, "memberAccessToken", {
        // TODO - Use the template below to add any global app settings that are unique
        //  to this app (ie. does not belong in CommonAppSettingsProvider)
        // get paramName() {
        //     return this._paramName.value;
        // }
        // get paramNameObservable() {
        //     return this._paramName.asObservable();
        // }
        // set paramName(val) {
        //     // TODO - Do validation if necessary before writing the new value
        //     this._paramName.next(val);
        // }
        get: function () {
            return this._memberAccessToken.value;
        },
        set: function (token) {
            // TODO - Do validation if necessary before writing the new value
            if (token === undefined || token === this._memberAccessToken.value) {
                return;
            }
            this._memberAccessToken.next(token);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AppDataService.prototype, "memberAccessTokenObservable", {
        get: function () {
            return this._memberAccessToken.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AppDataService.prototype, "serviceCallStatus", {
        get: function () {
            return this._serviceCallStatus.value;
        },
        set: function (status) {
            // TODO - Do validation if necessary before writing the new value
            if (status === undefined || status === this._serviceCallStatus.value) {
                return;
            }
            this._serviceCallStatus.next(status);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AppDataService.prototype, "serviceCallStatusObservable", {
        get: function () {
            return this._serviceCallStatus.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AppDataService.prototype, "truckDataAvailable", {
        get: function () {
            return this._truckDataAvailable.value;
        },
        set: function (dataAvailable) {
            var _dataAvailable = dataAvailable || false;
            // TODO - Do validation if necessary before writing the new value
            if (_dataAvailable === this._truckDataAvailable.value) {
                return;
            }
            this._truckDataAvailable.next(_dataAvailable);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AppDataService.prototype, "truckDataAvailableObservable", {
        get: function () {
            return this._truckDataAvailable.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AppDataService.prototype, "truckLocation", {
        get: function () {
            return this._truckLocation.value;
        },
        set: function (location) {
            // TODO - Do validation if necessary before writing the new value
            if (location === undefined) {
                return;
            }
            this._truckLocation.next(location);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AppDataService.prototype, "truckLocationObservable", {
        get: function () {
            return this._truckLocation.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AppDataService.prototype, "truckLocationWatchdogRef", {
        get: function () {
            return this._truckLocationWatchdogRef.value;
        },
        set: function (ref) {
            // TODO - Do validation if necessary before writing the new value
            if (ref === undefined) {
                return;
            }
            if (ref == null && this._truckLocationWatchdogRef.value) {
                clearInterval(this._truckLocationWatchdogRef.value);
                console.log('Clearing truck location watchdog timer');
            }
            if (ref !== null) {
                console.log('Setting up truck location watchdog timer');
            }
            this._truckLocationWatchdogRef.next(ref);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AppDataService.prototype, "otwEnabled", {
        get: function () {
            return this._otwEnabled.value;
        },
        set: function (enabled) {
            var _enabled = enabled || false;
            // TODO - Do validation if necessary before writing the new value
            if (_enabled === this._otwEnabled.value) {
                return;
            }
            this._otwEnabled.next(_enabled);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AppDataService.prototype, "otwEnabledObservable", {
        get: function () {
            return this._otwEnabled.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    AppDataService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AppDataService_Factory() { return new AppDataService(); }, token: AppDataService, providedIn: "root" });
    return AppDataService;
}(CommonAppSettingsProvider));
export { AppDataService };
