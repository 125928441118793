import { InjectableDocumentRef, InjectableWindowRef } from './browser-globals';
import * as i0 from "@angular/core";
import * as i1 from "./browser-globals";
export var GoaaaGoogleMapsScriptProtocol;
(function (GoaaaGoogleMapsScriptProtocol) {
    GoaaaGoogleMapsScriptProtocol[GoaaaGoogleMapsScriptProtocol["HTTP"] = 1] = "HTTP";
    GoaaaGoogleMapsScriptProtocol[GoaaaGoogleMapsScriptProtocol["HTTPS"] = 2] = "HTTPS";
    GoaaaGoogleMapsScriptProtocol[GoaaaGoogleMapsScriptProtocol["AUTO"] = 3] = "AUTO";
})(GoaaaGoogleMapsScriptProtocol || (GoaaaGoogleMapsScriptProtocol = {}));
var GoaaaMapsLoader = /** @class */ (function () {
    function GoaaaMapsLoader(w, d) {
        this._SCRIPT_ID = 'goaaaMapsApiScript';
        this.callbackName = 'goaaaMapsApiLoader';
        this._windowRef = w;
        this._documentRef = d;
    }
    GoaaaMapsLoader.prototype.load = function (config) {
        this._config = config;
        var window = this._windowRef.getNativeWindow();
        if (window.google && window.google.maps) {
            // Google maps already loaded on the page.
            return Promise.resolve();
        }
        if (this._scriptLoadingPromise) {
            return this._scriptLoadingPromise;
        }
        // this can happen in HMR situations or Stackblitz.io editors.
        var scriptOnPage = this._documentRef.getNativeDocument().getElementById(this._SCRIPT_ID);
        if (scriptOnPage) {
            this.assignScriptLoadingPromise(scriptOnPage);
            return this._scriptLoadingPromise;
        }
        var script = this._documentRef.getNativeDocument().createElement('script');
        script.type = 'text/javascript';
        script.async = true;
        script.defer = true;
        script.id = this._SCRIPT_ID;
        script.src = this.getScriptSrc(this.callbackName);
        this.assignScriptLoadingPromise(script);
        this._documentRef.getNativeDocument().body.appendChild(script);
        return this._scriptLoadingPromise;
    };
    GoaaaMapsLoader.prototype.assignScriptLoadingPromise = function (scriptElem) {
        var _this = this;
        this._scriptLoadingPromise = new Promise(function (resolve, reject) {
            _this._windowRef.getNativeWindow()[_this.callbackName] = function () {
                console.log('Maps script finished loading');
                resolve();
            };
            scriptElem.onerror = function (error) {
                reject(error);
            };
        });
    };
    GoaaaMapsLoader.prototype.getScriptSrc = function (callbackName) {
        var protocolType = (this._config && this._config.protocol) || GoaaaGoogleMapsScriptProtocol.HTTPS;
        var protocol;
        switch (protocolType) {
            case GoaaaGoogleMapsScriptProtocol.AUTO:
                protocol = '';
                break;
            case GoaaaGoogleMapsScriptProtocol.HTTP:
                protocol = 'http:';
                break;
            case GoaaaGoogleMapsScriptProtocol.HTTPS:
                protocol = 'https:';
                break;
        }
        var hostAndPath = this._config.hostAndPath || 'maps.googleapis.com/maps/api/js';
        var queryParams = {
            v: this._config.apiVersion || '3',
            callback: callbackName,
            key: this._config.apiKey,
            client: this._config.clientId,
            channel: this._config.channel,
            libraries: this._config.libraries,
            region: this._config.region,
            language: this._config.language
        };
        var params = Object.keys(queryParams)
            .filter(function (k) { return queryParams[k] != null; })
            .filter(function (k) {
            // remove empty arrays
            return !Array.isArray(queryParams[k]) ||
                (Array.isArray(queryParams[k]) && queryParams[k].length > 0);
        })
            .map(function (k) {
            // join arrays as comma seperated strings
            var i = queryParams[k];
            if (Array.isArray(i)) {
                return { key: k, value: i.join(',') };
            }
            return { key: k, value: queryParams[k] };
        })
            .map(function (entry) {
            return entry.key + "=" + entry.value;
        })
            .join('&');
        return protocol + "//" + hostAndPath + "?" + params;
    };
    GoaaaMapsLoader.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function GoaaaMapsLoader_Factory() { return new GoaaaMapsLoader(i0.ɵɵinject(i1.InjectableWindowRef), i0.ɵɵinject(i1.InjectableDocumentRef)); }, token: GoaaaMapsLoader, providedIn: "root" });
    return GoaaaMapsLoader;
}());
export { GoaaaMapsLoader };
